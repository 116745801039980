import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Content from '../components/content';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import '../styles/for-us.scss';

const About = ({ data }) => {
  const { certificates } = data;
  console.log(certificates)
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
      </Helmet>
      <Layout>
        <Content>
          <p>
            Строителна компания ПР СТРОЙ ООД е основана през 2018г от съдружниците Мирослав Пирев и инж. Цветан Ранчев с
            основна дейност ремонтни и довършителни работи. От пролетта на 2019г е регистрирана в КСБ и притежава
            удостоверение за строежи I група, 3та категория, когато започва да изпълнява обекти от етап груб
            строеж. В момента седи стабилна на пазара и е предпочитан партньор за редица инвеститори.
          </p>
        </Content>
        <Content>
          <div className="for-us-certificates">
            {certificates.edges.map(certificate => <GatsbyImage
              image={getImage(certificate.node)}
              title="Удостоверение за добро изпълнение"
              alt="Удостоверение за добро изпълнение"
            ></GatsbyImage>)}
          </div>
        </Content>
      </Layout>
    </Fragment>
  );
};

export default About;

export const query = graphql`
  query {
    certificates: allFile(filter: { relativePath: { regex: "/images/certificates/" } }) {
      edges {
        node {
          id
          base
          ...mediumImage
        }
      }
    }
  }
`;
